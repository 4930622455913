/* global scrollLock */
import vars from '../helpers';

scrollLock.disablePageScroll();

document.addEventListener('DOMContentLoaded', () => {
	gsap.timeline().to(vars.preloader, {
		onStart() {
			vars.main.classList.remove('is-hidden');
			vars.header.classList.remove('is-hidden');
			vars.footer.classList.remove('is-hidden');
		},
		onComplete() {
			vars.preloader.classList.add('is-hidden');
			scrollLock.enablePageScroll();
		},
		delay: 1,
		duration: 0.5,
		opacity: 0,
		ease: 'power2.out',
		clearProps: 'all',
	});
});

import Swiper, {Pagination, Navigation} from 'swiper';

const init = () => {
	const olympiadSlider = document.querySelectorAll('.olympiad__section-card');

	olympiadSlider.forEach((slider) => {
		const olympiadSwiper = new Swiper(slider, {
			speed: 500,
			spaceBetween: 10,
			slidesPerView: 'auto',
			modules: [Pagination, Navigation],
			pagination: {
				el: '.olympiad__pagination',
				clickable: true,
			},
			breakpoints: {
				1025: {
					slidesPerView: 4,
					spaceBetween: 20,
				},
			},
			navigation: {
				prevEl: '.olympiad__arrow-prev',
				nextEl: '.olympiad__arrow-next',
			},
		});
	});
};

export default {
	init,
};

const init = () => {
	const year = new Date().getFullYear();

	if (document.querySelector('[data-year]')) {
		document.querySelector('[data-year]').textContent = year;
	}
};

export default {
	init,
};

import '@babel/polyfill';
import svg4everybody from 'svg4everybody';
import scrollLock from 'scroll-lock';
import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

svg4everybody();

window.scrollLock = scrollLock;
window.gsap = gsap;

require('ninelines-ua-parser');

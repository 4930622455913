import vars from '../helpers';

const links = document.querySelectorAll('[href^="#"]');
const selectorFadeUp = document.querySelectorAll('[data-animate="fade-up"]');
const selectorFadeLeft = document.querySelectorAll('[data-animate="fade-left"]');
const selectorGroup = document.querySelectorAll('[data-animate="group"]');
const selectorIntro = document.querySelector('[data-animate="intro"]');
const selectorBeginSection = document.querySelector('[data-animate="begin-section"]');
const selectorNav = document.querySelectorAll('[data-selector="nav"]');
const animateDuration = 0.6;

let isAnimate = true;
let scrollTl = gsap.timeline();

const fadeUp = (element) => {
	if (element.classList.contains('is-animate')) {
		return;
	}

	const elementDelay = element.dataset.animateDelay ? element.dataset.animateDelay : false;

	gsap.timeline().to(element, {
		onComplete() {
			element.classList.add('is-animate');
		},
		duration: animateDuration,
		delay: elementDelay ? elementDelay : 0,
		opacity: 1,
		y: 0,
		ease: 'Power1.easeOut',
		clearProps: 'all',
	});
};

const fadeLeft = (element) => {
	if (element.classList.contains('is-animate')) {
		return;
	}

	const elementDelay = element.dataset.animateDelay ? element.dataset.animateDelay : false;

	gsap.timeline().to(element, {
		onComplete() {
			element.classList.add('is-animate');
		},
		duration: animateDuration,
		delay: elementDelay ? elementDelay : 0,
		opacity: 1,
		x: 0,
		ease: 'Power1.easeOut',
		clearProps: 'all',
	});
};

const animateGroup = (group) => {
	if (window.outerHeight / 2 + window.pageYOffset >= group.offsetTop) {
		const child = group.querySelectorAll('[data-animate-group]');

		child.forEach((el) => {
			fadeUp(el);
		});
	} else {
		gsap.delayedCall(0.1, () => {
			animateGroup(group);
		});
	}
};

const scrollTo = (current) => {
	const offset = document.querySelector(current).offsetTop - vars.header.offsetHeight;

	scrollTl.to(window, {
		duration: 0.7,
		scrollTo: offset,
		ease: 'Power3.easeOut',
	});
};

const sectionIntro = (element) => {
	if (element.classList.contains('is-animate')) {
		return;
	}

	const title = element.querySelector('.intro__title');
	const description = element.querySelector('.intro__description');
	const buttons = element.querySelector('.intro__buttons');
	const text = element.querySelector('.intro__text');
	const logo = element.querySelector('.intro__logo');
	const scenePath = element.querySelector('.intro__scene-path');
	const scenePathLength = scenePath.querySelector('path').getTotalLength();
	const sceneTeacher = element.querySelector('.intro__scene-teacher svg');

	scenePath.style.strokeDasharray = scenePathLength.toString();

	gsap.timeline()
		.from(title, {
			duration: animateDuration,
			opacity: 0,
			y: '50px',
			ease: 'Power3.easeOut',
		})
		.from(description, {
			duration: animateDuration,
			opacity: 0,
			y: '50px',
			ease: 'Power3.easeOut',
		}, `-=${animateDuration / 2}`)
		.from(buttons, {
			duration: animateDuration,
			opacity: 0,
			y: '50px',
			ease: 'Power3.easeOut',
		}, `-=${animateDuration / 2}`)
		.to(element, {
			onStart() {
				element.classList.add('is-animate');
			},
			duration: animateDuration,
		}, `-=${animateDuration / 2}`)
		.from(scenePath, {
			duration: animateDuration,
			strokeDashoffset: scenePathLength,
			ease: 'linear',
		})
		.from(sceneTeacher, {
			duration: animateDuration,
			opacity: 0,
			y: '10px',
			ease: 'Power3.easeOut',
		})
		.from(text, {
			duration: animateDuration,
			opacity: 0,
			x: '50px',
			ease: 'Power3.easeOut',
		}, `-=${animateDuration / 2}`)
		.from(logo, {
			duration: animateDuration,
			opacity: 0,
			x: '50px',
			ease: 'Power3.easeOut',
		}, `-=${animateDuration / 2}`);
};

const sectionBegin = (element) => {
	if (element.classList.contains('is-animate')) {
		return;
	}

	const sectionCard = element.querySelector('.begin__section-card[data-card-active="true"]');
	const card = sectionCard.querySelectorAll('.begin__card');

	gsap.to(element, {
		onStart() {
			card.forEach((el) => {
				el.style.opacity = 0;
			});
		},
		onComplete() {
			element.classList.add('is-animate');

			card.forEach((el, index) => {
				const cardVector = el.querySelector('.begin__card-vector');
				const cardVectorPath = cardVector.querySelectorAll('path');
				const cardIcon = el.querySelector('.begin__card-icon');
				const cardContent = el.querySelector('.begin__card-content');

				gsap.timeline()
					.to(el, {
						onStart() {
							cardVectorPath.forEach((path) => {
								const pathLength = path.getTotalLength();
								path.style.strokeDasharray = `${pathLength.toString()}px`;
								path.style.strokeDashoffset = `${pathLength.toString()}px`;
							});
						},
						onComplete() {
							cardVectorPath.forEach((path) => {
								gsap.to(path, {
									duration: animateDuration,
									strokeDashoffset: 0,
									ease: 'linear',
								});
							});
						},
						delay: animateDuration * 0.5 + (index + 1) / 10,
						duration: animateDuration,
						opacity: 1,
						ease: 'Power3.easeOut',
						clearProps: 'all',
					})
					.from(cardIcon, {
						delay: animateDuration,
						duration: animateDuration,
						opacity: 0,
						ease: 'Power1.easeOut',
						clearProps: 'all',
					})
					.from(cardContent, {
						duration: animateDuration,
						y: '10px',
						opacity: 0,
						ease: 'Power3.easeOut',
						clearProps: 'all',
					}, `-=${animateDuration * 0.5}`);
			});
		},
		duration: animateDuration,
		opacity: 1,
		ease: 'Power1.easeOut',
		clearProps: 'all',
	});
};

const selectorSwitch = () => {
	selectorNav.forEach((selector) => {
		selector.addEventListener('click', () => {
			const sections = document.querySelectorAll('[data-selector="section"]');
			const number = selector.dataset.nav;

			if (selector.dataset.navActive === 'false') {
				sections.forEach((section) => {
					section.querySelector(`[data-nav="${number === '1' ? '2' : '1'}"]`).dataset.navActive = 'false';
					section.querySelector(`[data-card="${number === '1' ? '2' : '1'}"]`).dataset.cardActive = 'false';
					section.querySelector(`[data-nav="${number}"]`).dataset.navActive = 'true';
					section.querySelector(`[data-card="${number}"]`).dataset.cardActive = 'true';
				});
			}
		});
	});
};

const observeInit = () => {
	const observers = new IntersectionObserver((entries, observer) => {
		entries.forEach((section) => {
			if (section.isIntersecting && isAnimate) {
				observer.unobserve(section.target);

				if (section.target.dataset.animate === 'fade-up') {
					fadeUp(section.target);
				}

				if (section.target.dataset.animate === 'fade-left') {
					fadeLeft(section.target);
				}

				if (section.target.dataset.animate === 'group') {
					animateGroup(section.target);
				}

				if (section.target.dataset.animate === 'intro') {
					sectionIntro(section.target);
				}

				if (section.target.dataset.animate === 'begin-section') {
					sectionBegin(section.target);
				}
			}
		});
	}, {threshold: 0.3});

	selectorGroup.forEach((section) => {
		observers.observe(section);
	});

	selectorFadeUp.forEach((section) => {
		observers.observe(section);
	});

	selectorFadeLeft.forEach((section) => {
		observers.observe(section);
	});

	observers.observe(selectorIntro);
	observers.observe(selectorBeginSection);
};

const init = () => {
	observeInit();
	selectorSwitch();

	links.forEach((link) => {
		link.addEventListener('click', (e) => {
			e.preventDefault();

			scrollTo(link.getAttribute('href'));
		});
	});
};

export default {
	init,
	fadeUp,
};

import vars from '../helpers';

let data;
let regions;
let universities;
let page = 1;

const csrfToken = document.querySelector('input[name="csrfmiddlewaretoken"]').value;

const navSubmit = document.querySelector('.nav__submit');
const navButton = document.querySelectorAll('.nav__button');
const navSearch = document.querySelectorAll('.nav__search-input');
const catalogSectionCard = document.querySelector('.catalog__section-card');
const catalogButton = document.querySelector('.catalog__button');
const catalogReset = document.querySelector('.catalog__reset');
const catalogResetButton = document.querySelector('.catalog__reset-button');
const catalogWait = document.querySelectorAll('.catalog__wait');
const regionSelectText = document.querySelectorAll('.region + button .nav__select-text');
const universitySelectText = document.querySelectorAll('.university + button .nav__select-text');
const regionSelect = document.querySelectorAll('select.nav__field.region');
const universitySelect = document.querySelectorAll('select.nav__field.university');
const formContainers = document.querySelectorAll('.nav__form');

const innerPartner = (partner) => {
	let tag = '';

	partner.forEach((company) => {
		tag = `${tag}<div class="catalog__card-tag">${company.title}</div>`;
	});

	return tag;
};

const innerCard = (card) => {
	const inner = `
        <div class="catalog__card-image"><img src="assets/${card.image}" alt="Program"></div>
        <div class="catalog__card-content">
            <h3 class="catalog__card-title">${card.title}</h3>
            <div class="catalog__card-tags">
                ${card.qualification ? `<div class="catalog__card-tag">${card.qualification}</div>` : ''}
                ${card.university_short ? `<div class="catalog__card-tag">${card.university_short}</div>` : ''}
                ${card.partner_companies.length > 0 ? innerPartner(card.partner_companies) : ''}
            </div>
            <p class="catalog__card-text">${card.brief}</p>
            <button class="catalog__card-link" type="button">
                <span>Подробнее</span>
                <svg><use xlink:href="/static/guide/images/sprites.svg#arrow-circle"></use></svg>
            </button>
            ${card.has_poa ? '<div class="catalog__card-logo" data-selector="tooltip"><img src="/static/guide/images/icons/logo-poa.svg" alt="POA logo"></div>' : ''}
        </div>
    `;

	return inner;
};

const createCheckbox = ({id, title}, type, isMobile = false) => {
	const checkbox = document.createElement('div');
	const input = document.createElement('input');
	const label = document.createElement('label');

	checkbox.className = 'nav__checkbox';

	input.type = 'checkbox';
	input.name = type;
	input.value = id;
	input.className = 'nav__field';

	label.innerText = title;

	if (isMobile) {
		label.htmlFor = `catalog-mobile-${type}-${id}`;
		input.id = `catalog-mobile-${type}-${id}`;
	} else {
		label.htmlFor = `catalog-${type}-${id}`;
		input.id = `catalog-${type}-${id}`;
	}

	checkbox.appendChild(input);
	checkbox.appendChild(label);

	return checkbox;
};

const createOption = (id, title) => {
	const option = document.createElement('option');

	option.value = id;
	option.innerText = title;

	return option;
};

const createCard = (card) => {
	const create = document.createElement('div');

	create.className = 'catalog__card';
	create.dataset.animate = 'fade-card';
	create.dataset.id = card.id;
	create.innerHTML = innerCard(card);

	return create;
};

const diplaySkeleton = (isShown) => {
	if (isShown) {
		catalogWait.forEach((el) => {
			el.classList.remove('is-hidden');
		});
	} else {
		catalogWait.forEach((el) => {
			el.classList.add('is-hidden');
		});
	}
};

const disableInputs = (isShown) => {
	if (isShown) {
		formContainers.forEach((el) => {
			el.style.pointerEvents = 'none';
		});
	} else {
		formContainers.forEach((el) => {
			el.style.pointerEvents = '';
		});
	}
};

const displayCatalogButton = () => {
	const total = catalogSectionCard.querySelectorAll('.catalog__card').length;

	catalogButton.removeAttribute('disabled');

	if (data.total_items === total) {
		catalogButton.classList.add('is-hidden');
	} else {
		catalogButton.classList.remove('is-hidden');
	}
};

const addCard = () => {
	const total = catalogSectionCard.querySelectorAll('.catalog__card').length;

	vars.programs = [...vars.programs, ...data.items];

	if (!total.length && !data.items.length) {
		catalogReset.classList.remove('is-hidden');
	} else {
		catalogReset.classList.add('is-hidden');
	}

	data.items.forEach((item) => {
		catalogSectionCard.append(createCard(item));
	});

	displayCatalogButton();
};

const removeCards = () => {
	const cards = document.querySelectorAll('.catalog__card');
	cards.forEach((card) => card.remove());
	vars.programs = [];
};

const addPartners = () => {
	document.querySelectorAll('.partner').forEach((partner) => {
		const isMobile = !!partner.closest('.filter');
		__partner_companies.forEach((el) => {
			partner.appendChild(createCheckbox(el, 'partner', isMobile));
		});
	});
};

const addStudyForms = () => {
	document.querySelectorAll('.studyForm').forEach((form) => {
		const isMobile = !!form.closest('.filter');
		__study_forms.forEach((el) => {
			form.appendChild(createCheckbox(el, 'study_form', isMobile));
		});
	});
};

const addQualifications = () => {
	document.querySelectorAll('.qualification').forEach((qualification) => {
		const isMobile = !!qualification.closest('.filter');
		__qualifications.forEach((el) => {
			qualification.appendChild(createCheckbox(el, 'qualification', isMobile));
		});
	});
};

const addRegions = () => {
	document.querySelectorAll('.region').forEach((region) => {
		const defaultOption = document.createElement('option');
		defaultOption.innerText = '–';
		defaultOption.value = '';

		region.appendChild(defaultOption);

		regions.forEach((el) => {
			region.appendChild(createOption(el.id, el.title));
		});
	});
};

const addUniversities = () => {
	document.querySelectorAll('.university').forEach((university) => {
		const defaultOption = document.createElement('option');
		defaultOption.innerText = '–';
		defaultOption.value = '';

		university.appendChild(defaultOption);

		universities.forEach((el) => {
			university.appendChild(createOption(el.id, el.title));
		});
	});
};

const fetchRegions = async () => {
	await fetch('/regions', {
		method: 'POST',
		mode: 'same-origin',
		headers: {'X-CSRFToken': csrfToken},
		body: JSON.stringify({q: ''}),
	})
		.then((response) => response.json())
		.then((fetchData) => {
			regions = fetchData;
			addRegions();
		})
		.catch((error) => console.error(error));
};

const fetchUniversities = async () => {
	await fetch('/universities', {
		method: 'POST',
		mode: 'same-origin',
		headers: {'X-CSRFToken': csrfToken},
		body: JSON.stringify({q: ''}),
	})
		.then((response) => response.json())
		.then((fetchData) => {
			universities = fetchData;
			addUniversities();
		})
		.catch((error) => console.error(error));
};

const fetchPrograms = async () => {
	await fetch('/programs', {
		method: 'POST',
		mode: 'same-origin',
		headers: {'X-CSRFToken': csrfToken},
		body: JSON.stringify({q: '',
			page: '1',
			per_page: '3'}),
	})
		.then((response) => response.json())
		.then((fetchData) => {
			data = fetchData;
			addCard();
		})
		.catch((error) => console.error(error));
};

const submit = async (form) => {
	const formData = new FormData(form);
	const qualification = [];
	const studyForm = [];
	const partner = [];
	formData.append('page', page);
	formData.append('per_page', 3);

	if (!formData.get('region')) {
		formData.delete('region');
	}

	if (!formData.get('university')) {
		formData.delete('university');
	}

	const formDataObj = Object.fromEntries(formData);

	if (formData.has('qualification')) {
		form.querySelectorAll('input[name=qualification]:checked').forEach((element) => {
			qualification.push(element.value);
		});

		formDataObj.qualification = qualification;
	}

	if (formData.has('study_form')) {
		form.querySelectorAll('input[name=study_form]:checked').forEach((element) => {
			studyForm.push(element.value);
		});

		formDataObj.study_form = studyForm;
	}

	if (formData.has('partner')) {
		form.querySelectorAll('input[name=partner]:checked').forEach((element) => {
			partner.push(element.value);
		});

		formDataObj.partner = partner;
	}

	if (formData.has('has_poa')) {
		formDataObj.has_poa = true;
	}

	const json = JSON.stringify(formDataObj);

	await fetch('/programs', {
		method: 'POST',
		mode: 'same-origin',
		headers: {'X-CSRFToken': csrfToken},
		body: json,
	})
		.then((response) => response.json())
		.then((fetchData) => {
			data = fetchData;
			setTimeout(() => {
				diplaySkeleton(false);
				disableInputs(false);
				addCard();
			}, 1200);
		})
		.catch((error) => console.error(error));
};

const debounce = (fn, delay) => {
	let timeoutId;

	return (...args) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			fn(...args);
		}, delay);
	};
};

const handleFormSubmit = (e) => {
	const form = e.target.closest('.nav').querySelector('.nav__form');

	diplaySkeleton(true);
	removeCards();
	catalogButton.classList.add('is-hidden');
	catalogReset.classList.add('is-hidden');

	page = 1;

	submit(form);
};

const debouncedHandleFormSubmit = debounce(handleFormSubmit, 500);

const init = () => {
	fetchPrograms();
	fetchRegions();
	fetchUniversities();

	addPartners();
	addStudyForms();
	addQualifications();

	regionSelect.forEach((el) => {
		el.addEventListener('change', (event) => {
			const title = event.target.value ? regions.find((region) => region.id === Number(event.target.value)).title : '';

			regionSelectText.forEach((text) => {
				text.innerText = event.target.value ? title : 'Выберите регион';
			});
		});
	});

	universitySelect.forEach((el) => {
		el.addEventListener('change', (event) => {
			const title = event.target.value ? universities.find((university) => university.id === Number(event.target.value)).title : '';
			universitySelectText.forEach((text) => {
				text.innerText = event.target.value ? title : 'Выберите ВУЗ';
			});
		});
	});

	catalogButton.addEventListener('click', () => {
		const isMobile = window.innerWidth <= 1024;
		let form;

		if (isMobile) {
			form = document.querySelector('.filter .nav__form');
		} else {
			form = document.querySelector('.catalog__nav .nav__form');
		}

		catalogButton.setAttribute('disabled', 'disabled');
		catalogReset.classList.add('is-hidden');

		page += 1;

		submit(form);
	});

	const fields = document.querySelectorAll('.catalog__section-nav .nav__field');

	fields.forEach((el) => {
		el.addEventListener('change', debouncedHandleFormSubmit);
	});

	navSearch.forEach((el) => {
		el.addEventListener('input', debounce((e) => {
			if (e.target.value.length < 1 && e.target.value.length !== 0) {
				return;
			}

			const form = e.target.closest('.nav').querySelector('.nav__form');

			diplaySkeleton(true);
			disableInputs(true);
			removeCards();
			catalogButton.classList.add('is-hidden');
			catalogReset.classList.add('is-hidden');

			page = 1;

			submit(form);
		}, 500));
	});

	navSubmit.addEventListener('click', (e) => {
		const form = e.target.closest('.nav').querySelector('.nav__form');

		diplaySkeleton(true);
		disableInputs(true);
		removeCards();
		catalogButton.classList.add('is-hidden');
		catalogReset.classList.add('is-hidden');

		page = 1;

		submit(form);
	});

	catalogResetButton.addEventListener('click', () => {
		const mobileForm = document.querySelector('.filter .nav__form');
		const form = document.querySelector('.catalog__nav .nav__form');

		diplaySkeleton(true);
		disableInputs(true);
		removeCards();
		catalogButton.classList.add('is-hidden');
		catalogReset.classList.add('is-hidden');

		mobileForm.reset();
		form.reset();

		page = 1;

		submit(form);
	});

	navButton.forEach((button) => {
		button.addEventListener('click', () => {
			const point = button.closest('.nav__point');

			point.classList.toggle('is-open');
		});
	});
};

export default {
	init,
};

import vars from '../helpers';
/* global scrollLock */

const popup = document.querySelector('.popup');
const popupOverlay = popup.querySelector('.popup__overlay');
const popupContainer = popup.querySelector('.popup__container');
const popupWrapper = popup.querySelector('.popup__wrapper');
const popupImage = popup.querySelector('.popup__image img');
const popupNote = popup.querySelector('[data-card="2"] .popup__note');
const popupTitle = popup.querySelector('[data-card="2"] .popup__title');
const popupText = popup.querySelector('[data-card="2"] .popup__text p');
const popupTextUl = popup.querySelector('[data-card="1"] .popup__text ul');
const popupPartner = popup.querySelector('[data-card="1"] .popup__partner');
const popupSpecial = popup.querySelector('[data-card="2"] .popup__special');
const popupLink = popup.querySelector('.popup__link');
const popupClose = popup.querySelectorAll('.popup__close, .popup__overlay');
const partnerImages = document.querySelector('.popup__partner-images');
const catalogSection = document.querySelector('.catalog__section-card');
const animateDuration = 0.4;

let popupTl = gsap.timeline();

const open = (item) => {
	popupTl
		.from(popupOverlay, {
			onStart() {
				scrollLock.disablePageScroll(popupWrapper);
				popup.classList.remove('is-hidden');
				popupLink.setAttribute('href', item.site_link);
				popupNote.innerHTML = item.qualification;
				popupTitle.innerHTML = item.title;
				popupText.innerHTML = item.description;

				if (item.image) {
					popupImage.setAttribute('src', `/assets/${item.image}`);
				}

				if (item.university) {
					const create = document.createElement('li');

					create.innerHTML = item.university;
					popupTextUl.append(create);
				}

				if (item.rating_place || item.rating_place !== '0') {
					const create = document.createElement('li');

					create.innerHTML = `Место вуза в&nbsp;рейтинге&nbsp;&mdash;&nbsp;${item.rating_place}`;
					popupTextUl.append(create);
				}

				if (item.study_form || item.study_duration) {
					const create = document.createElement('li');

					create.innerHTML = `${item.study_form ? `${item.study_form},&nbsp;` : ''}${item.study_duration ? `${item.study_duration}` : ''}`;
					popupTextUl.append(create);
				}

				const isBudgetPlace = item.budget_place_count && item.budget_place_count !== '0';
				const isContractPlace = item.contract_place_count && item.contract_place_count !== '0';

				if (isBudgetPlace || isContractPlace) {
					const create = document.createElement('li');

					create.innerHTML = `${isBudgetPlace ? `${item.budget_place_count} бюджетных мест,&nbsp;` : ''}${isContractPlace ? `${item.contract_place_count}&nbsp;контрактных` : ''}`;
					popupTextUl.append(create);
				}

				if (item.payed_place_count && item.payed_place_count !== '0') {
					const create = document.createElement('li');

					create.innerHTML = `Платные места для иностранцев&nbsp;&mdash;&nbsp;${item.payed_place_count}`;
					popupTextUl.append(create);
				}

				if (item.granted_place_count && item.granted_place_count !== '0') {
					const create = document.createElement('li');

					create.innerHTML = `Платныe Гранты Правительства РФ&nbsp;&mdash;&nbsp;${item.granted_place_count}`;
					popupTextUl.append(create);
				}

				if (item.exam_info) {
					const create = document.createElement('li');

					create.innerHTML = `Вступительные: ${item.exam_info}`;
					popupTextUl.append(create);
				}

				if (item.exams.length) {
					const create = document.createElement('li');

					const examString = item.exams.map((exam) => {
						return `${exam.subject} - ${exam.avg_exam_score}`;
					}).join(', ');

					create.innerHTML = `ЕГЭ: ${examString}`;
					popupTextUl.append(create);
				}

				if (item.last_year_passing_ball && item.last_year_passing_ball !== '0') {
					const create = document.createElement('li');

					create.innerHTML = `Проходной балл в прошлом году&nbsp;&mdash;&nbsp;${item.last_year_passing_ball}`;
					popupTextUl.append(create);
				}

				if (item.has_poa) {
					popupSpecial.classList.remove('is-hidden');
				}

				if (item.partner_companies.length > 0) {
					item.partner_companies.forEach((company) => {
						const create = document.createElement('div');

						create.className = 'popup__partner-image';
						create.innerHTML = `<img src="${company.image}" alt="${company.title}">`;

						partnerImages.append(create);
					});

					popupPartner.classList.remove('is-hidden');
				}
			},
			duration: animateDuration,
			opacity: 0,
			ease: 'Power3.easeOut',
		})
		.from(popupContainer, {
			duration: animateDuration,
			x: '-110%',
			opacity: 0,
			ease: 'Power3.easeOut',
		}, `-=${animateDuration * 0.5}`)
		.to([popupContainer, popupOverlay], {
			duration: 0,
			clearProps: 'all',
		});
};

const closed = () => {
	popupTl
		.to(popupContainer, {
			duration: animateDuration,
			x: '110%',
			opacity: 0,
			ease: 'Power3.easeOut',
		})
		.to(popupOverlay, {
			duration: animateDuration,
			opacity: 0,
			ease: 'Power3.easeOut',
		}, `-=${animateDuration * 0.5}`)
		.to([popupContainer, popupOverlay], {
			onComplete() {
				scrollLock.enablePageScroll(popupWrapper);
				popup.classList.add('is-hidden');
				popupImage.setAttribute('src', 'static/images/base.jpg');
				popupLink.setAttribute('href', '');
				popupNote.innerHTML = '';
				popupTitle.innerHTML = '';
				popupText.innerHTML = '';
				popupTextUl.innerHTML = '';
				partnerImages.innerHTML = '';
				popupSpecial.classList.add('is-hidden');
				popupPartner.classList.add('is-hidden');
			},
			duration: 0,
			clearProps: 'all',
		});
};

const init = () => {
	catalogSection.addEventListener('click', (e) => {
		const link = e.target.closest('.catalog__card-link');

		if (link !== null) {
			const id = link.closest('.catalog__card').dataset.id.toString();
			const dataItem = vars.programs.find((item) => item.id.toString() === id);

			open(dataItem);
		}
	});

	popupClose.forEach((close) => {
		close.addEventListener('click', () => {
			closed();
		});
	});
};

export default {
	init,
};

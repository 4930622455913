import vars from '../helpers';

const selectorEvent = document.querySelectorAll('.catalog, .filter');
const tooltip = document.querySelector('.tooltip');
let tooltipPos = null;
let tooltipTop = 0;
let tooltipLeft = 0;
let tooltipTl = gsap.timeline();

const round = (value) => {
	return Math.round(value);
};

const open = (selector) => {
	const selectorTooltip = selector.target.closest('[data-selector="tooltip"]') ?? false;

	if (selectorTooltip) {
		selector.preventDefault();
		tooltipPos = selectorTooltip.dataset.tooltipPos ?? false;

		gsap.from(tooltip, {
			onStart() {
				tooltip.classList.remove('is-hidden');
				tooltipTop = vars.isMobile() ?
					round(selectorTooltip.getBoundingClientRect().top + window.pageYOffset + selectorTooltip.offsetHeight + 20) :
					round(selectorTooltip.getBoundingClientRect().top + window.pageYOffset - tooltip.offsetHeight / 2 + selectorTooltip.offsetHeight / 2);
				tooltipLeft = tooltipPos && tooltipPos === 'right' ?
					round(selectorTooltip.getBoundingClientRect().left + selectorTooltip.offsetWidth + 20) :
					vars.isMobile() ? (vars.winWidth - tooltip.offsetWidth) / 2 :
					round(selectorTooltip.getBoundingClientRect().left - tooltip.offsetWidth - 20);
				tooltip.style.top = `${tooltipTop}px`;
				tooltip.style.left = `${tooltipLeft}px`;

				if (tooltipPos) {
					tooltip.classList.add(`tooltip--${tooltipPos}`);
				}
			},
			duration: 0.15,
			y: 7,
			opacity: 0,
			ease: 'Power1.easeOut',
		});
	}
};

const close = (selector) => {
	const selectorTooltip = selector.target.closest('[data-selector="tooltip"]') ?? false;

	if (selectorTooltip) {
		tooltipTl.to(tooltip, {
			onStart() {
				tooltipTl.paused();
			},
			onComplete() {
				tooltip.classList.add('is-hidden');

				if (tooltipPos) {
					tooltip.classList.remove(`tooltip--${tooltipPos}`);
					tooltipPos = null;
				}
			},
			duration: 0.15,
			y: 7,
			opacity: 0,
			ease: 'Power1.easeOut',
			clearProps: 'all',
		});
	}
};

const init = () => {
	selectorEvent.forEach((selector) => {
		if (vars.isDesktop()) {
			selector.addEventListener('mouseover', (e) => {
				open(e);
			});
			selector.addEventListener('mouseout', (e) => {
				close(e);
			});
		} else {
			selector.addEventListener('touchstart', (e) => {
				open(e);
			});

			selector.addEventListener('touchend', (e) => {
				close(e);
			});
		}
	});
};

export default {
	init,
};

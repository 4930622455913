import './vendor';
import './helpers';
import './components/preloader';
import animation from './components/animation';
import header from './components/header';
import catalog from './components/catalog';
import slider from './components/slider';
import filter from './components/filter';
import popup from './components/popup';
import tooltip from './components/tooltip';
import year from './components/year';

document.addEventListener('DOMContentLoaded', () => {
	header.init();
	catalog.init();
	slider.init();
	filter.init();
	popup.init();
	tooltip.init();
	animation.init();
	year.init();
});

let vars = {};

vars.html = document.querySelector('html');
vars.header = document.querySelector('header');
vars.main = document.querySelector('main');
vars.footer = document.querySelector('footer');
vars.preloader = document.querySelector('.preloader');
vars.isMobile = () => innerWidth <= 767;
vars.isTablet = () => innerWidth >= 768 && innerWidth <= 1024;
vars.isDesktop = () => innerWidth >= 1025;
vars.winWidth = window.innerWidth;
vars.programs = [];

export default vars;

/* global scrollLock */
const filter = document.querySelector('.filter');
const filterOverlay = document.querySelector('.filter__overlay');
const filterNav = document.querySelector('.filter__nav');
const catalogFilter = document.querySelector('.catalog__filter');
const navClose = document.querySelectorAll('.nav__close, .filter__overlay');
const navSubmit = document.querySelector('.nav__submit');
const animateDuration = 0.3;

let navTl = gsap.timeline();

const open = () => {
	navTl
		.from(filterOverlay, {
			onStart() {
				scrollLock.disablePageScroll(filterNav);
				filter.classList.remove('is-hidden');
			},
			duration: animateDuration,
			opacity: 0,
			ease: 'Power3.easeOut',
		})
		.from(filterNav, {
			duration: animateDuration,
			x: '-110%',
			ease: 'Power3.easeOut',
		}, `-=${animateDuration * 0.5}`)
		.to([filterNav, filterOverlay], {
			duration: 0,
			clearProps: 'all',
		});
};

const closed = () => {
	navTl
		.to(filterNav, {
			duration: animateDuration,
			x: '-110%',
			ease: 'Power3.easeOut',
		})
		.to(filterOverlay, {
			duration: animateDuration,
			opacity: 0,
			ease: 'Power3.easeOut',
		}, `-=${animateDuration * 0.5}`)
		.to([filterNav, filterOverlay], {
			onComplete() {
				scrollLock.enablePageScroll(filterNav);
				filter.classList.add('is-hidden');
			},
			duration: 0,
			clearProps: 'all',
		});
};

const init = () => {
	catalogFilter.addEventListener('click', () => {
		open();
	});

	navClose.forEach((close) => {
		close.addEventListener('click', () => {
			closed();
		});
	});

	navSubmit.addEventListener('click', () => {
		closed();
	});
};

export default {
	init,
};
